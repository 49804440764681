<template>
  <div>
    <ais-instant-search
        :search-client="searchClient"
        :index-name="sort"
        :routing="routing"
        :search-function="winerySearchFunction"
        v-if="sort"
    >


      <ais-panel class="winery-search">
        <div class="filter-parent bg-grey-100 p-8 mt-8">
          <div class="filter-toggle-sort flex flex-row justify-between">
            <!--          <button type="button" :class="[{'border-white bg-white hover:bg-primary-500 hover:text-white': !showFilters}, {'border-grey-200 bg-grey-250 hover:bg-grey-400': showFilters}]" class="px-4 py-4 text-black font-semibold text-16 uppercase border transition-colors duration-300 focus:outline-none text-center" @click.prevent="toggleFilters">-->
            <!--            <span class="small-label md:hidden">-->
            <!--              <span class="active" v-if="showFilters">Close</span>-->
            <!--              <span class="inactive" v-else>Filter</span>-->
            <!--            </span>-->
            <!--            <span class="large-label hidden md:inline-block">-->
            <!--              <span class="active" v-if="showFilters">Close Filters</span>-->
            <!--              <span class="inactive" v-else>Filter Results</span>-->
            <!--            </span>-->
            <!--          </button>-->
            <div class="filter-toggle-sort flex flex-row justify-between">
              <button type="button" class="btn-filter" @click.prevent="toggleFilters">
                filter
              </button>
            </div>
          </div>
          <div class="filter-container">
            <div class="filter-list md:flex md:flex-row md:-mx-4">
              <div class="filter-column md:flex-1 md:px-4 2xl:pr-20">
                <div class="filter-set border-b border-grey-200">
                  <div class="filter-heading heading mt-12 text-14 uppercase py-4 cursor-pointer">State</div>
                  <div class="filter-expander">
                    <ais-refinement-list :limit="100" attribute="state" :sort-by="['name:asc']">
                      <div slot-scope="{ items, refine, createURL }" class="custom-select">
                        <ul class="custom-select--options">
                          <li v-for="item in items" :key="item.value">
                            <a
                                :href="createURL(item)"
                                @click.prevent="refine(item.value)"
                                :class="['custom-select--item', {active: item.isRefined}]"
                            >
                              {{ item.label }}
                              <span class="count">{{ item.count.toLocaleString() }}</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </ais-refinement-list>
                  </div>
                </div>

                <div class="filter-set border-b border-grey-200">
                  <div class="filter-heading heading mt-12 text-14 uppercase py-4 cursor-pointer">Region</div>
                  <div class="filter-expander">
                    <ais-refinement-list :limit="100" attribute="region" :sort-by="['name:asc']">
                      <div slot-scope="{ items, refine, createURL }" class="custom-select">
                        <ul class="custom-select--options">
                          <li v-for="item in items" :key="item.value">
                            <a
                                :href="createURL(item)"
                                @click.prevent="refine(item.value)"
                                :class="['custom-select--item', {active: item.isRefined}]"
                            >
                              {{ item.label }}
                              <span class="count">{{ item.count.toLocaleString() }}</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </ais-refinement-list>
                  </div>
                </div>

                <div class="filter-set border-b border-grey-200">
                  <div class="filter-heading heading mt-12 text-14 uppercase py-4 cursor-pointer">Event / Program</div>
                  <div class="filter-expander">
                    <ais-refinement-list :limit="100" attribute="experiences" :sort-by="['name:asc']">
                      <div slot-scope="{ items, refine, createURL }" class="custom-select">
                        <ul class="custom-select--options">
                          <li v-for="item in items" :key="item.value">
                            <a
                                :href="createURL(item)"
                                @click.prevent="refine(item.value)"
                                :class="['custom-select--item', {active: item.isRefined}]"
                            >
                              {{ item.label }}
                              <span class="count">{{ item.count.toLocaleString() }}</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </ais-refinement-list>
                  </div>
                </div>
              </div>

              <div class="filter-column md:flex-1 md:px-4 2xl:pr-20">
                <div class="filter-set border-b border-grey-200">
                  <div class="filter-heading heading mt-12 text-14 uppercase py-4 cursor-pointer">Available In</div>
                  <div class="filter-expander">
                    <ais-refinement-list :limit="100" attribute="availableIn" :sort-by="['name:asc']">
                      <div slot-scope="{ items, refine, createURL }" class="custom-select">
                        <ul class="custom-select--options">
                          <li v-for="item in items" :key="item.value">
                            <a
                                :href="createURL(item)"
                                @click.prevent="refine(item.value)"
                                :class="['custom-select--item', {active: item.isRefined}]"
                            >
                              {{ item.label }}
                              <span class="count">{{ item.count.toLocaleString() }}</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </ais-refinement-list>
                  </div>
                </div>

                <div class="filter-set border-b border-grey-200">
                  <div class="filter-heading heading mt-12 text-14 uppercase py-4 cursor-pointer">Seeking Distribution
                    In
                  </div>
                  <div class="filter-expander">
                    <ais-refinement-list :limit="100" attribute="seekingDistribution" :sort-by="['name:asc']">
                      <div slot-scope="{ items, refine, createURL }" class="custom-select">
                        <ul class="custom-select--options">
                          <li v-for="item in items" :key="item.value">
                            <a
                                :href="createURL(item)"
                                @click.prevent="refine(item.value)"
                                :class="['custom-select--item', {active: item.isRefined}]"
                            >
                              {{ item.label }}
                              <span class="count">{{ item.count.toLocaleString() }}</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </ais-refinement-list>
                  </div>
                </div>

                <div class="filter-set border-b border-grey-200">
                  <div class="filter-heading heading mt-12 text-14 uppercase py-4 cursor-pointer">Seeking Additional Distribution In</div>
                  <div class="filter-expander">
                    <ais-refinement-list :limit="100" attribute="additionalSeekingDistribution" :sort-by="['name:asc']">
                      <div slot-scope="{ items, refine, createURL }" class="custom-select">
                        <ul class="custom-select--options">
                          <li v-for="item in items" :key="item.value">
                            <a
                                :href="createURL(item)"
                                @click.prevent="refine(item.value)"
                                :class="['custom-select--item', {active: item.isRefined}]"
                            >
                              {{ item.label }}
                              <span class="count">{{ item.count.toLocaleString() }}</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </ais-refinement-list>
                  </div>
                </div>
              </div>

              <div class="filter-column md:flex-1 md:px-4 2xl:pr-20">
                <div class="filter-set border-b border-grey-200">
                  <div class="filter-heading heading mt-12 text-14 uppercase py-4 cursor-pointer">Format</div>
                  <div class="filter-expander">
                    <ais-refinement-list :limit="100" attribute="format" :sort-by="['name:asc']">
                      <div slot-scope="{ items, refine, createURL }" class="custom-select">
                        <ul class="custom-select--options">
                          <li v-for="item in items" :key="item.value">
                            <a
                                :href="createURL(item)"
                                @click.prevent="refine(item.value)"
                                :class="['custom-select--item', {active: item.isRefined}]"
                            >
                              {{ item.label }}
                              <span class="count">{{ item.count.toLocaleString() }}</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </ais-refinement-list>
                  </div>
                </div>
                <div class="filter-set border-b border-grey-200">
                  <div class="filter-heading heading mt-12 text-14 uppercase py-4 cursor-pointer">Sustainability</div>
                  <div class="filter-expander">
                    <ais-refinement-list :limit="100" attribute="properties" :sort-by="['name:asc']">
                      <div slot-scope="{ items, refine, createURL }" class="custom-select">
                        <ul class="custom-select--options">
                          <li v-for="item in items" :key="item.value">
                            <a
                                :href="createURL(item)"
                                @click.prevent="refine(item.value)"
                                :class="['custom-select--item', {active: item.isRefined}]"
                            >
                              {{ item.label }}
                              <span class="count">{{ item.count.toLocaleString() }}</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </ais-refinement-list>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ais-panel>
      <ais-panel>
        <div slot="default" slot-scope="{ hasRefinements }"
             class="current-filters bg-grey-150 p-8 flex flex-col md:flex-row md:items-start" v-show="hasRefinements">
          <ais-current-refinements class="flex-grow mr-8">
            <div class="text-white w-full" slot-scope="{ items, createURL }" v-if="items.length">

              <ul class="md:flex md:flex-row md:flex-wrap">
                <li v-for="item in items" :key="item.attribute" class="p-4 bg-grey-100 block mt-2 md:mr-2">
                  <div class="leading-1 text-12">
                    <span v-if="item.label === 'pricePoint'">Price Point</span>
                    <span v-if="item.label === 'style'">Wine Style</span>
                    <span v-if="item.label === 'type'">Wine Type</span>
                    <span v-if="item.label === 'abv'">ABV</span>
                    <span v-if="item.label === 'variety'">Variety</span>
                    <span v-if="item.label === 'region'">Region</span>
                    <span v-if="item.label === 'state'">State</span>
                    <span v-if="item.label === 'availableIn'">Available In</span>
                    <span v-if="item.label === 'seekingDistribution'">Seeking Distribution In</span>
                    <span v-if="item.label === 'properties'">Other</span>
                    <span v-if="item.label === 'additionalServices'">Additional Services</span>:
                    <span v-if="item.label === 'format'">Format</span>:
                  </div>
                  <ul class="flex flex-row flex-wrap -mx-1 mt-4">
                    <li class="m-1"
                        v-for="refinement in item.refinements"
                        :key="[
                refinement.attribute,
                refinement.type,
                refinement.value,
                refinement.operator
              ].join(':')"
                    >
                      <button
                          class="bg-white py-2 pl-2 pr-8 relative text-black text-12 hover:bg-grey-200 hover:text-white transition-colors duration-300 focus:outline-none text-left inline-block leading-1"
                          :href="createURL(refinement)"
                          @click.prevent="item.refine(refinement)"
                      >
                        {{ refinement.label }}
                        <svg aria-hidden="true" width="10" height="10" focusable="false" data-prefix="far"
                             data-icon="times"
                             class="svg-inline--fa fa-times fa-w-10 absolute right-3 top-1/2 transform -translate-y-1/2"
                             role="img" xmlns="http://www.w3.org/2000/svg"
                             viewBox="0 0 320 512">
                          <path fill="currentColor"
                                d="M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z"></path>
                        </svg>
                      </button>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </ais-current-refinements>
          <ais-clear-refinements>
            <div slot-scope="{ canRefine, refine, createURL }" class="text-white">
              <button
                  class="clear-filters-button flex-shrink-0 bg-grey-150px-8 p-4 text-grey-400 font-semibold text-14 uppercase hover:text-white transition-colors duration-300 focus:outline-none flex flex-row items-center justify-center leading-1 min-w-0 mt-8"
                  :href="createURL()"
                  @click.prevent="refine"
                  v-if="canRefine"
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 27.87 28.543"
                     class="stroke-current mr-4 flex-shrink-0">
                  <g id="Action_Go_Back" data-name="Action_Go Back" transform="translate(1.435 1.393)">
                    <g id="Group_5" data-name="Group 5">
                      <path id="Stroke_1" data-name="Stroke 1" d="M0,0,25,25.758" fill="none" stroke-miterlimit="10"
                            stroke-width="4"/>
                      <path id="Stroke_3" data-name="Stroke 3" d="M25,0,0,25.758" fill="none" stroke-miterlimit="10"
                            stroke-width="4"/>
                    </g>
                  </g>
                </svg>
                Clear Filters
              </button>
            </div>
          </ais-clear-refinements>
        </div>
      </ais-panel>

      <div class="flex flex-row items-center mt-8 justify-end">
        <div class="text-grey-400 text-16 mt-px">Sort by</div>
        <ais-sort-by
            :class-names="{
                  'ais-SortBy-select': 'sort-select'
                }"
            :items="[
                      { value: 'wineries_name_asc', label: 'Name A-Z' },
                      { value: 'wineries_name_desc', label: 'Name Z-A' },
                      { value: 'wineries_state_asc', label: 'State A-Z' },
                      { value: 'wineries_state_desc', label: 'State Z-A' },
                      { value: 'wineries_region_asc', label: 'Region A-Z' },
                      { value: 'wineries_region_desc', label: 'Region Z-A' },
                    ]"
        >
        </ais-sort-by>
      </div>

      <ais-state-results>
        <div slot-scope="{query, nbHits, index}">
          <algolia-stats :index="index" update-count="wineries"></algolia-stats>

          <div v-if="nbHits">
            <!--            <ais-stats class="mt-12 text-center">-->
            <!--              <p class="text-grey-400" slot-scope="{ hitsPerPage, nbPages, nbHits, page, processingTimeMS, query }">-->
            <!--                Showing <span class="text-grey-900">{{ nbHits }} wineries</span><span v-if="query"> for <span class="text-grey-900">{{ query }}</span></span>-->
            <!--              </p>-->
            <!--            </ais-stats>-->
            <ais-hits class="mt-16">

              <div slot-scope="{ items }" class="winery-list-results">
                <masonry
                    :cols="{default: 3, 1023: 2, 767: 1}"
                    :gutter="{default: '30px'}"
                >
                  <winery-result-tile v-for="winery in items" :key="'winery' + winery.objectID" :winery="winery"/>
                </masonry>
              </div>
              <!--              <div slot-scope="{ items }" class="winery-list sm:flex sm:flex-row sm:flex-wrap -mx-4">-->
              <!--                <div class="winery-tile w-full p-4 sm:w-1/2 lg:w-1/3" v-for="winery in items" :key="'winery' + winery.objectID">-->
              <!--                  <router-link :to="'/expo/' + winery.slug" class="block text-white winery-inner bg-grey-100 pl-8 pb-8 h-full">-->
              <!--                    <div class="image pl-8">-->
              <!--                      <div class="image-container relative pb-9/16">-->
              <!--                        <img :src="`${winery.image}?w=500`" :alt="winery.name" class="block absolute left-0 top-0 w-full h-full object-cover max-w-none">-->
              <!--                      </div>-->
              <!--                    </div>-->
              <!--                    <div class="title mt-12 text-18 font-bold pr-8 leading-3"><ais-highlight :hit="winery" attribute="name" /></div>-->
              <!--                    <div class="region mt-4 opacity-50 pr-8 leading-3"><ais-highlight :hit="winery" attribute="region" />, <span class="uppercase"><ais-highlight :hit="winery" attribute="state" /></span></div>-->
              <!--                  </router-link>-->
              <!--                </div>-->
              <!--              </div>-->

            </ais-hits>
            <div class="pagination mt-12 flex flex-row justify-center">
              <ais-pagination :padding="1" class="md:hidden" @page-change="pageChange">
                <ul class="flex flex-row space-x-2"
                    slot-scope="{
                currentRefinement,
                nbPages,
                pages,
                isFirstPage,
                isLastPage,
                refine,
                createURL
              }"
                >
                  <li>
                    <a class="btn-pagination text-white bg-grey-100"
                       :href="createURL(currentRefinement - 1)"
                       @click.prevent="refine(currentRefinement - 1)"
                    >
                      ‹
                    </a>
                  </li>
                  <li v-for="page in pages" :key="page">
                    <a class="btn-pagination"
                       :href="createURL(page)"
                       :class="[{ 'text-white bg-grey-100' : page !== currentRefinement}, { 'text-black bg-white' : page === currentRefinement}]"
                       @click.prevent="refine(page)"
                    >
                      {{ page + 1 }}
                    </a>
                  </li>
                  <li>
                    <a class="btn-pagination text-white bg-grey-100"
                       :href="createURL(currentRefinement + 1)"
                       @click.prevent="refine(currentRefinement + 1)"
                    >
                      ›
                    </a>
                  </li>
                </ul>
              </ais-pagination>
              <ais-pagination class="hidden md:block" @page-change="pageChange">
                <ul class="flex flex-row space-x-2"
                    slot-scope="{
                currentRefinement,
                nbPages,
                pages,
                isFirstPage,
                isLastPage,
                refine,
                createURL
              }"
                >
                  <li>
                    <a class="btn-pagination text-white bg-grey-100"
                       :href="createURL(currentRefinement - 1)"
                       @click.prevent="refine(currentRefinement - 1)"
                    >
                      ‹
                    </a>
                  </li>
                  <li v-for="page in pages" :key="page">
                    <a class="btn-pagination"
                       :href="createURL(page)"
                       :class="[{ 'text-white bg-grey-100' : page !== currentRefinement}, { 'text-black bg-white' : page === currentRefinement}]"
                       @click.prevent="refine(page)"
                    >
                      {{ page + 1 }}
                    </a>
                  </li>
                  <li>
                    <a class="btn-pagination text-white bg-grey-100"
                       :href="createURL(currentRefinement + 1)"
                       @click.prevent="refine(currentRefinement + 1)"
                    >
                      ›
                    </a>
                  </li>
                </ul>
              </ais-pagination>
            </div>
          </div>
          <div v-if="!nbHits" class="mt-8 text-16">No results found for the query: {{ query }}</div>
        </div>
      </ais-state-results>

      <ais-configure :query="query"></ais-configure>

    </ais-instant-search>
  </div>
</template>

<script>
import algoliasearch from 'algoliasearch/lite';
import historyRouter from '@/customHistory';
import {simple as simpleMapping} from 'instantsearch.js/es/lib/stateMappings';
// import 'instantsearch.css/themes/algolia-min.css';
import AlgoliaStats from "@/components/AlgoliaStats";
import $ from 'jquery'
import Vue from 'vue'
import VueMasonry from 'vue-masonry-css'

Vue.use(VueMasonry);
import WineryResultTile from "@/components/WineryResultTile";
import Analytics from '@/analytics'
import {debounce} from 'debounce'

export default {
  name: 'WinerySearch',
  components: {WineryResultTile, AlgoliaStats},
  props: {
    query: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      searchClient: algoliasearch(
          process.env.VUE_APP_ALGOLIA_APPID,
          process.env.VUE_APP_ALGOLIA_APIKEY
      ),
      hits: 0,
      sort: this.$store.state.winerySort,
      showFilters: true,
      routing: {
        router: historyRouter(),
        stateMapping: simpleMapping(),
      },
      initialised: false
    }
  },
  methods: {
    winerySearchFunction(helper) {
      helper.search()
      this.trackWinerySearch(helper)
    },
    // winerySearchFunction: debounce(function (helper) {
    //   if (this.initialised) {
    //     Analytics.trackPage({
    //       title: 'Expo',
    //       url: `${location.origin}/expo?query=${helper.state.query}&page=${helper.state.page}&availableIn=${helper.state.disjunctiveFacetsRefinements.availableIn.join(',')}&properties=${helper.state.disjunctiveFacetsRefinements.properties.join(',')}&region=${helper.state.disjunctiveFacetsRefinements.region.join(',')}&seekingDistribution=${helper.state.disjunctiveFacetsRefinements.seekingDistribution.join(',')}&state=${helper.state.disjunctiveFacetsRefinements.state.join(',')}`,
    //       path: `/expo?query=${helper.state.query}&page=${helper.state.page}&availableIn=${helper.state.disjunctiveFacetsRefinements.availableIn.join(',')}&properties=${helper.state.disjunctiveFacetsRefinements.properties.join(',')}&region=${helper.state.disjunctiveFacetsRefinements.region.join(',')}&seekingDistribution=${helper.state.disjunctiveFacetsRefinements.seekingDistribution.join(',')}&state=${helper.state.disjunctiveFacetsRefinements.state.join(',')}`,
    //       referrer: `${location.origin}/expo`
    //     })
    //   }
    //   this.initialised = true
    //   helper.search()
    // }, 200),
    toggleFilters() {
      this.showFilters = !this.showFilters
      if (this.showFilters) {
        $(this.$el).find('.filter-container').slideDown()
      } else {
        $(this.$el).find('.filter-container').slideUp()
      }
    },
    pageChange() {
      $('html, body').animate({scrollTop: $('.results').offset().top - $('header .main').height() - 20}, 250)
    }
  },
  mounted() {
    console.log('win mounted')
    // const self = this
    // if (this.$route.fullPath.indexOf('?wineries') === -1 && this.$route.fullPath.indexOf('?products') === -1) {
    //   const sorts = ['wineries_name_asc', 'wineries_name_desc', 'wineries_state_asc', 'wineries_state_desc']
    //   setTimeout(function () {
    //     self.sort = sorts[Math.floor(Math.random() * sorts.length)];
    //   }, 500)
    // }
    $('.winery-search .filter-heading').click(function (e) {
      e.preventDefault();
      $(this).toggleClass('active');
      if ($(this).hasClass('active')) {
        $(this).next('.filter-expander').slideDown(100)
      } else {
        $(this).next('.filter-expander').slideUp(100)
      }
    });
  },
  created() {
    this.trackWinerySearch = debounce(function (helper) {
      Analytics.trackPage({
        title: 'Expo',
        url: `${location.origin}/expo?query=${helper.state.query}&page=${helper.state.page}&availableIn=${helper.state.disjunctiveFacetsRefinements.availableIn.join(',')}&properties=${helper.state.disjunctiveFacetsRefinements.properties.join(',')}&region=${helper.state.disjunctiveFacetsRefinements.region.join(',')}&seekingDistribution=${helper.state.disjunctiveFacetsRefinements.seekingDistribution.join(',')}&state=${helper.state.disjunctiveFacetsRefinements.state.join(',')}`,
        path: `/expo?query=${helper.state.query}&page=${helper.state.page}&availableIn=${helper.state.disjunctiveFacetsRefinements.availableIn.join(',')}&properties=${helper.state.disjunctiveFacetsRefinements.properties.join(',')}&region=${helper.state.disjunctiveFacetsRefinements.region.join(',')}&seekingDistribution=${helper.state.disjunctiveFacetsRefinements.seekingDistribution.join(',')}&state=${helper.state.disjunctiveFacetsRefinements.state.join(',')}`,
        referrer: `${location.origin}/expo`
      })
    }, 500)
  }
}
</script>

<style lang="scss">

.filter-expander {
  display: none;
}

.filter-heading {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3E%3Cpath stroke='%23FFFFFF' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3E%3C/svg%3E");
  background-position: right 0.5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
}

.ais-RefinementList--noRefinement:after {
  content: 'No filters';
  display: block;
  margin-bottom: 10px;
  opacity: 0.2;
  font-size: 12px !important;
}

.winery-list-results > div > div:nth-child(even) {
  margin-top: -30px;
}

.ais-RefinementList-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: -5px;
}

.ais-RefinementList-item {
  margin: 5px;
}

.clear-filters-button {
  width: 100%;
  @media(min-width: 768px) {
    width: 146px;
  }
}

.ais-RefinementList-label {
  padding: 10px;
  font-size: 12px !important;
  color: #FFF;
  background-color: #1A1A1A;
  display: block;
  line-height: 1 !important;
  cursor: pointer;

  &:hover {
    background-color: #222222;
  }

  input {
    position: absolute;
    opacity: 0;
    visibility: hidden;
  }

  .ais-RefinementList-labelText {
    font-size: 12px !important;
  }

  .ais-RefinementList-count {
    padding: 0 !important;
    background-color: transparent !important;
    color: #686868;
    font-size: 12px !important;

    &:before {
      display: inline-block;
      content: '(';
    }

    &:after {
      display: inline-block;
      content: ')';
    }
  }
}

.ais-RefinementList-item--selected {
  .ais-RefinementList-label {
    color: #FFF;
    background-color: #333;

    &:hover {
      background-color: #444;
    }

    .ais-RefinementList-count {
      color: #686868;
    }
  }
}

.heading {
  color: #FFF;
}

.custom-select {
  position: relative;
}

.custom-select--options {
  max-height: 120px;
  overflow: auto;

  @media(min-width: 768px) {
    max-height: 240px;
  }
}

.custom-select--options::-webkit-scrollbar {
  width: 12px;
}

.custom-select--options::-webkit-scrollbar-track {
  background-color: #222;
}

.custom-select--options::-webkit-scrollbar-thumb {
  background-color: #999;
  box-shadow: inset 0 0 0 1px #222;
}

.custom-select--item {
  padding: 10px;
  font-size: 12px !important;
  color: #FFF;
  background-color: #1A1A1A;
  display: block;
  line-height: 1 !important;
  cursor: pointer;
  border-bottom: 1px solid #111;

  .count {
    color: #686868;
    font-size: 12px !important;
    white-space: nowrap;

    &:before {
      display: inline-block;
      content: '(';
    }

    &:after {
      display: inline-block;
      content: ')';
    }
  }

  &:hover {
    background-color: #222222;
  }

  &.active {
    background-color: #333;

    &:hover {
      background-color: #444;
    }
  }
}
</style>
