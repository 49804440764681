<template>
  <div>

<!--    <div class="sticky-search-box bg-black py-4 md:py-8 z-30 fixed left-0 right-0 top-20 md:top-60 border-b border-grey-100">-->
<!--      <div class="outer-container">-->
<!--        <div class="inner-container">-->
<!--          <form @submit.prevent="submitSearch" class="relative">-->
<!--            <input type="text" class="bg-white text-16 block w-full py-4 leading-3 px-4 mt-2 leading-1 text-black appearance-none focus:outline-none focus:shadow-inner md:h-28 md:px-10" v-model="query" placeholder="Enter a wine or winery">-->
<!--            <button type="submit" class="search-button bg-grey-100 text-white text-16 hover:bg-grey-200 hover:text-white transition-colors duration-300 focus:outline-none text-center inline-block leading-1 border border-white flex flex-row items-center px-4 md:px-8">-->
<!--              <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="search" class="svg-inline&#45;&#45;fa fa-search fa-w-16 mr-4" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="20" height="20"><path fill="currentColor" d="M508.5 468.9L387.1 347.5c-2.3-2.3-5.3-3.5-8.5-3.5h-13.2c31.5-36.5 50.6-84 50.6-136C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c52 0 99.5-19.1 136-50.6v13.2c0 3.2 1.3 6.2 3.5 8.5l121.4 121.4c4.7 4.7 12.3 4.7 17 0l22.6-22.6c4.7-4.7 4.7-12.3 0-17zM208 368c-88.4 0-160-71.6-160-160S119.6 48 208 48s160 71.6 160 160-71.6 160-160 160z"></path></svg>-->
<!--              Search-->
<!--            </button>-->
<!--          </form>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->

    <div class="outer-container">
      <div class="inner-container">
        <div class="search-hero mt-16">
          <bleed-container bleed="left">
            <div class="background-image absolute left-0 right-0 w-full h-full z-10">
              <img src="@/assets/search-hero3.jpg" class="block absolute left-0 top-0 w-full lg:w-9/10 h-full object-cover max-w-none z-10">
<!--              <div class="grad from-black bg-gradient-to-l absolute right-0 top-0 w-1/5 h-full z-20"></div>-->
            </div>
            <div class="bleed-wrapper relative z-20">
              <div class="hero-content pr-8 lg:px-40 max-w-screen-lg">
                <div class="heading"><span>Browse</span><br>&amp; <span>Search</span></div>
                <div class="search-box mt-12 lg:mt-20 relative">
                  <form @submit.prevent="submitSearch">
<!--                    <input type="text" class="bg-white text-16 block w-full py-4 leading-3 px-4 mt-2 leading-1 text-black appearance-none focus:outline-none focus:shadow-inner md:h-28 md:px-10 rounded-none" v-model="query" placeholder="What are you looking for?" @input="$store.commit('setQuery', query)">-->
                    <input type="text" class="bg-white text-16 block w-full py-4 leading-3 px-4 mt-2 leading-1 text-black appearance-none focus:outline-none focus:shadow-inner md:h-28 md:px-10 rounded-none" v-model="query" placeholder="Enter a search term or use the filters below">
                    <button type="button" class="clear-button absolute right-48 text-black top-6 focus:outline-none hover:text-primary-500 h-16 w-16 text-center" v-show="query.length" @click="query = ''">
                      <svg width="20" height="20" aria-hidden="true" focusable="false" data-prefix="fal" data-icon="times-square" class="svg-inline--fa fa-times-square fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M400 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zm16 400c0 8.8-7.2 16-16 16H48c-8.8 0-16-7.2-16-16V80c0-8.8 7.2-16 16-16h352c8.8 0 16 7.2 16 16v352zm-97.2-245.3L249.5 256l69.3 69.3c4.7 4.7 4.7 12.3 0 17l-8.5 8.5c-4.7 4.7-12.3 4.7-17 0L224 281.5l-69.3 69.3c-4.7 4.7-12.3 4.7-17 0l-8.5-8.5c-4.7-4.7-4.7-12.3 0-17l69.3-69.3-69.3-69.3c-4.7-4.7-4.7-12.3 0-17l8.5-8.5c4.7-4.7 12.3-4.7 17 0l69.3 69.3 69.3-69.3c4.7-4.7 12.3-4.7 17 0l8.5 8.5c4.6 4.7 4.6 12.3 0 17z"></path></svg>
                    </button>
                    <button type="submit" class="search-button bg-grey-100 text-white text-16 hover:bg-grey-200 hover:text-white transition-colors duration-300 focus:outline-none text-center inline-block leading-1 border border-white flex flex-row items-center px-4 md:px-8">
                      <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="search" class="svg-inline--fa fa-search fa-w-16 mr-4" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="20" height="20"><path fill="currentColor" d="M508.5 468.9L387.1 347.5c-2.3-2.3-5.3-3.5-8.5-3.5h-13.2c31.5-36.5 50.6-84 50.6-136C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c52 0 99.5-19.1 136-50.6v13.2c0 3.2 1.3 6.2 3.5 8.5l121.4 121.4c4.7 4.7 12.3 4.7 17 0l22.6-22.6c4.7-4.7 4.7-12.3 0-17zM208 368c-88.4 0-160-71.6-160-160S119.6 48 208 48s160 71.6 160 160-71.6 160-160 160z"></path></svg>
                      Search
                    </button>
                  </form>
                </div>
              </div>

              <div class="count-boxes"></div>
            </div>
          </bleed-container>
        </div>
      </div>
    </div>

    <div class="tabs border-b border-white border-opacity-10 mt-16 md:mt-24">
      <div class="outer-container">
        <div class="inner-container">
          <div class="tab-buttons flex flex-row items-center -mx-8">
            <button type="button" class="h-28 md:h-40 focus:outline-none flex flex-row items-center px-8 md:mr-16" :class="{'active': view === 'wineries'}" @click="viewWineries">Wineries ({{wineryHits}})</button>
            <button type="button" class="h-28 md:h-40 focus:outline-none flex flex-row items-center px-8" :class="{'active': view === 'products'}" @click="viewProducts">Products ({{productHits}})</button>
          </div>
        </div>
      </div>
    </div>

    <div class="results" v-if="ready">
      <div class="outer-container">
        <div class="inner-container">
          <winery-search :query="query" v-show="view === 'wineries'" />
          <product-search :query="query" v-show="view === 'products'" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Analytics from '@/analytics'
import BleedContainer from "@/components/BleedContainer";
import WinerySearch from "@/components/WinerySearch";
import Bus from '@/events/EventBus';
import $ from 'jquery';
import ProductSearch from "@/components/ProductSearch";
import Kontent from "@/kontent";
export default {
  name: 'Expo',
  metaInfo () {
    if (!this.page) {
      return null
    }

    let meta = [
      {property: 'og:site_name', content: 'CONNECT'},
      {property: 'og:url', content: window.location.href},
      {property: 'og:title', content: this.page.elements.page__meta_title.value.length ? this.page.elements.page__meta_title.value : this.page.elements.page__page_title.value},
      {property: 'og:description', content: this.page.elements.page__page_description.value},
      {name: 'title', content: this.page.elements.page__meta_title.value.length ? this.page.elements.page__meta_title.value : this.page.elements.page__page_title.value},
      {name: 'keywords', content: this.page.elements.page__meta_keywords.value},
      {name: 'description', content: this.page.elements.page__meta_description.value.length ? this.page.elements.page__meta_description.value : this.page.elements.page__page_description.value},
      {name: 'twitter:card', content: 'summary_large_image'},
      {name: 'twitter:title', content: this.page.elements.page__meta_title.value.length ? this.page.elements.page__meta_title.value : this.page.elements.page__page_title.value},
      {name: 'twitter:description', content: this.page.elements.page__meta_description.value.length ? this.page.elements.page__meta_description.value : this.page.elements.page__page_description.value}
    ]

    // if there's an image
    if (this.page.elements.page__main_image.value.length) {
      meta.push({property: 'og:image', content: this.page.elements.page__main_image.value[0].url})
      meta.push({name: 'twitter:image', content: this.page.elements.page__main_image.value[0].url})
    }

    // if there's a type
    if (this.page.elements.page__page_type.value.length) {
      meta.push({property: 'og:type', content: this.page.elements.page__page_type.value[0].name})
    }

    return {
      title: this.page.elements.page__page_title.value.length ? this.page.elements.page__page_title.value : 'CONNECT',
      meta: meta
    }
  },
  components: {ProductSearch, WinerySearch, BleedContainer},
  props: {},
  data() {
    return {
      view: this.$store.state.searchView,
      query: this.$store.state.query,
      wineryHits: 0,
      productHits: 0,
      ready: false,
      page: null
    }
  },
  methods: {
    viewWineries() {
      this.view = 'wineries'
      this.$store.commit('setSearchView', 'wineries')
    },
    viewProducts() {
      this.view = 'products'
      this.$store.commit('setSearchView', 'products')
    },
    submitSearch () {
      $('html, body').animate({scrollTop: $('.tabs').offset().top - $('header .main').height()}, 250)
    }
  },
  mounted() {
    Analytics.trackPage('Expo')

    Kontent.getItemByType('expo_page').then((res) => {
      this.page = res.data.items[0]

      setTimeout(function(){window.prerenderReady = true}, 1000)
    })

    let checkQuery = Object.keys(this.$route.query).join('')
    if (checkQuery.indexOf('refinementList') !== -1) {
      // a filter has been passed

      const q = Object.keys(this.$route.query)
      for (let i = 0; i < q.length; i++) {
        const key = q[i];
        if (key.indexOf('products_') !== -1) {
          // a product filter has been passed
          this.$store.dispatch("updateProductSort", key.split('[')[0])
        }

        if (key.indexOf('wineries_') !== -1) {
          // a winery filter has been passed
          this.$store.dispatch("updateWinerySort", key.split('[')[0])
        }
      }


    }

    if (this.$route.query.from) {
      switch (this.$route.query.from) {
        case 'landing':
          Analytics.trackEvent('Navigation', {category: 'Landing Promo', label: 'Expo'})
              break
        case 'header':
          Analytics.trackEvent('Navigation', {category: 'Header Navigation', label: 'Expo'})
              break
        case 'mobilemenu':
          Analytics.trackEvent('Navigation', {category: 'Mobile Menu', label: 'Expo'})
              break
      }
    }

    Bus.$on('wineries-hits', (hits) => {
      this.wineryHits = hits
    })
    Bus.$on('products-hits', (hits) => {
      this.productHits = hits
    })

    this.$store.dispatch("getFavouriteProducts")
    this.$store.dispatch("getFavouriteWineries")

    if (this.$route.fullPath.indexOf('?products') !== -1) {
      this.view = 'products'
    }

    if (this.$route.query.query) {
      this.query = this.$route.query.query
    } else {
      this.query = ''
    }

    this.ready = true
    console.log('mounted')

    if (this.$route.query.scroll === 'true') {
      console.log('scroll')
      setTimeout(function () {
        $('html, body').animate({scrollTop: $('.tabs').offset().top - $('header .main').height()}, 250)
      }, 500)

    }

    // $(window).on('scroll', function(){
    //   if ($('.search-hero .search-box').length) {
    //     if ($(document).scrollTop() > $('.search-hero .search-box').offset().top) {
    //       $('.sticky-search-box').addClass('active')
    //     } else {
    //       $('.sticky-search-box').removeClass('active')
    //     }
    //   }
    // });
  },
  beforeDestroy() {
    Bus.$off('wineries-hits')
    Bus.$off('products-hits')
  }
}
</script>

<style scoped lang="scss">
.tabs {
  font-size: 16px;
  @media(min-width: 768px)
  {
    font-size: 22px;
  }
}
.tab-buttons {
  button {
    position: relative;
    &:after {
      display: block;
      content: '';
      position: absolute;
      right: 5px;
      bottom: 0;
      height: 1px;
      transition: all 0.3s;
      background-color: #E8490F;
      opacity: 0;
      width: 70%;
    }

    &:hover {
      &:after {
        height: 2px;
        opacity: 1;
      }
    }

    &.active, &.active:hover {
      &:after {
        height: 8px;
        opacity: 1;
      }
    }
  }
}
.hero-content {
  padding-top: 50px;
  padding-bottom: 50px;

  @media(min-width: 768px)
  {
    padding-top: 70px;
    padding-bottom: 70px;
  }
}
.hero-content .heading {
  font-size: 30px;
  text-transform: uppercase;
  span {
    font-weight: bold;
  }

  line-height: 1.1;

  @media(min-width: 768px)
  {
    font-size: 68px;
  }
}
.helper {
  font-size: 9px;
  text-align: right;
  margin-top: 5px;
}
.search-button {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
}
.sticky-search-box {
  transform: translateY(-100%);
  transition: all 0.3s ease;
  opacity: 0;

  &.active {
    transform: none;
    opacity: 1;
  }
}
</style>
