<template>
  <div class="connect-stats">

  </div>
</template>

<script>
import { createWidgetMixin } from 'vue-instantsearch';
import { connectStats } from 'instantsearch.js/es/connectors';
import Bus from '@/events/EventBus';
export default {
  name: 'AlgoliaStats',
  props: {
    index: {
      type: String,
      default: ''
    },
    updateCount: {
      type: String,
      default: ''
    }
  },
  mixins: [
    createWidgetMixin({ connector: connectStats })
  ],
  watch: {
    state () {
      Bus.$emit(this.updateCount + '-hits', this.state.nbHits)
    }
  }
}
</script>

<style scoped lang="scss">

</style>
