<template>
  <div>
    <ais-instant-search
        :search-client="searchClient"
        :index-name="sort"
        :routing="routing"
        :search-function="productSearchFunction"
        v-if="sort"
    >

      <ais-panel class="product-search">
        <div class="filter-parent bg-grey-100 p-8 mt-8">
          <div class="filter-toggle-sort flex flex-row justify-between">
            <!--            <button type="button" :class="[{'border-white bg-white hover:bg-primary-500 hover:text-white': !showFilters}, {'border-grey-200 bg-grey-250 hover:bg-grey-400': showFilters}]" class="px-4 py-4 text-black font-semibold text-16 uppercase border transition-colors duration-300 focus:outline-none text-center" @click.prevent="toggleFilters">-->
            <button type="button" class="btn-filter" @click.prevent="toggleFilters">
              filter
              <!--              <span class="small-label md:hidden">-->
              <!--              <span class="active" v-if="showFilters">Close</span>-->
              <!--              <span class="inactive" v-else>Filter</span>-->
              <!--            </span>-->
              <!--              <span class="large-label hidden md:inline-block">-->
              <!--              <span class="active" v-if="showFilters">Close Filters</span>-->
              <!--              <span class="inactive" v-else>Filter Results</span>-->
              <!--            </span>-->
            </button>
          </div>
          <div class="filter-container">
            <div class="filter-list md:flex md:flex-row md:-mx-4">
              <div class="filter-column md:flex-1 md:px-4 2xl:pr-20">
                <div class="filter-set border-b border-grey-200">
                  <div class="filter-heading heading mt-12 text-14 uppercase py-4 cursor-pointer">State</div>
                  <div class="filter-expander">
                    <ais-refinement-list :limit="100" attribute="state" :sort-by="['name:asc']">
                      <div slot-scope="{ items, refine, createURL }" class="custom-select">
                        <ul class="custom-select--options">
                          <li v-for="item in items" :key="item.value">
                            <a
                                :href="createURL(item)"
                                @click.prevent="refine(item.value)"
                                :class="['custom-select--item', {active: item.isRefined}]"
                            >
                              {{ item.label }}
                              <span class="count">{{ item.count.toLocaleString() }}</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </ais-refinement-list>
                  </div>
                </div>

                <div class="filter-set border-b border-grey-200">
                  <div class="filter-heading heading mt-12 text-14 uppercase py-4 cursor-pointer">Region</div>
                  <div class="filter-expander">
                    <ais-refinement-list :limit="100" attribute="region" :sort-by="['name:asc']">
                      <div slot-scope="{ items, refine, createURL }" class="custom-select">
                        <ul class="custom-select--options">
                          <li v-for="item in items" :key="item.value">
                            <a
                                :href="createURL(item)"
                                @click.prevent="refine(item.value)"
                                :class="['custom-select--item', {active: item.isRefined}]"
                            >
                              {{ item.label }}
                              <span class="count">{{ item.count.toLocaleString() }}</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </ais-refinement-list>
                  </div>
                </div>

                <div class="filter-set border-b border-grey-200">
                  <div class="filter-heading heading mt-12 text-14 uppercase py-4 cursor-pointer">Event / Program</div>
                  <div class="filter-expander">
                    <ais-refinement-list :limit="100" attribute="experiences" :sort-by="['name:asc']">
                      <div slot-scope="{ items, refine, createURL }" class="custom-select">
                        <ul class="custom-select--options">
                          <li v-for="item in items" :key="item.value">
                            <a
                                :href="createURL(item)"
                                @click.prevent="refine(item.value)"
                                :class="['custom-select--item', {active: item.isRefined}]"
                            >
                              {{ item.label }}
                              <span class="count">{{ item.count.toLocaleString() }}</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </ais-refinement-list>
                  </div>
                </div>

                <div class="filter-set border-b border-grey-200">
                  <div class="filter-heading heading mt-12 text-14 uppercase py-4 cursor-pointer">Natural & Other</div>
                  <div class="filter-expander">
                    <ais-refinement-list :limit="100" attribute="alternativeWines" :sort-by="['name:asc']">
                      <div slot-scope="{ items, refine, createURL }" class="custom-select">
                        <ul class="custom-select--options">
                          <li v-for="item in items" :key="item.value">
                            <a
                                :href="createURL(item)"
                                @click.prevent="refine(item.value)"
                                :class="['custom-select--item', {active: item.isRefined}]"
                            >
                              {{ item.label }}
                              <span class="count">{{ item.count.toLocaleString() }}</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </ais-refinement-list>
                  </div>
                </div>

              </div>
              <div class="filter-column md:flex-1 md:px-4 2xl:pr-20">
                <div class="filter-set border-b border-grey-200">
                  <div class="filter-heading heading mt-12 text-14 uppercase py-4 cursor-pointer">Wine Type</div>
                  <div class="filter-expander">
                    <ais-refinement-list :limit="100" attribute="type" :sort-by="['name:asc']">
                      <div slot-scope="{ items, refine, createURL }" class="custom-select">
                        <ul class="custom-select--options">
                          <li v-for="item in items" :key="item.value">
                            <a
                                :href="createURL(item)"
                                @click.prevent="refine(item.value)"
                                :class="['custom-select--item', {active: item.isRefined}]"
                            >
                              {{ item.label }}
                              <span class="count">{{ item.count.toLocaleString() }}</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </ais-refinement-list>
                  </div>
                </div>
                <div class="filter-set border-b border-grey-200">
                  <div class="filter-heading heading mt-12 text-14 uppercase py-4 cursor-pointer">Variety</div>
                  <div class="filter-expander">
                    <ais-refinement-list :limit="100" attribute="variety" :sort-by="['name:asc']">
                      <div slot-scope="{ items, refine, createURL }" class="custom-select">
                        <ul class="custom-select--options">
                          <li v-for="item in items" :key="item.value">
                            <a
                                :href="createURL(item)"
                                @click.prevent="refine(item.value)"
                                :class="['custom-select--item', {active: item.isRefined}]"
                            >
                              {{ item.label }}
                              <span class="count">{{ item.count.toLocaleString() }}</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </ais-refinement-list>
                  </div>
                </div>
                <div class="filter-set border-b border-grey-200">
                  <div class="filter-heading heading mt-12 text-14 uppercase py-4 cursor-pointer">Wine Style</div>
                  <div class="filter-expander">
                    <ais-refinement-list :limit="100" attribute="style" :sort-by="['name:asc']">
                      <div slot-scope="{ items, refine, createURL }" class="custom-select">
                        <ul class="custom-select--options">
                          <li v-for="item in items" :key="item.value">
                            <a
                                :href="createURL(item)"
                                @click.prevent="refine(item.value)"
                                :class="['custom-select--item', {active: item.isRefined}]"
                            >
                              {{ item.label }}
                              <span class="count">{{ item.count.toLocaleString() }}</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </ais-refinement-list>
                  </div>
                </div>

              </div>
              <div class="filter-column md:flex-1 md:px-4 2xl:pr-20">
                <div class="filter-set border-b border-grey-200">
                  <div class="filter-heading heading mt-12 text-14 uppercase py-4 cursor-pointer">$AUD FOB / 9L Case
                  </div>
                  <div class="filter-expander">
                    <ais-refinement-list :limit="100" attribute="pricePoint" :sort-by="['name:asc']"
                                         :transform-items="transformPricePoint">
                      <div slot-scope="{ items, refine, createURL }" class="custom-select">
                        <ul class="custom-select--options">
                          <li v-for="item in items" :key="item.value">
                            <a
                                :href="createURL(item)"
                                @click.prevent="refine(item.value)"
                                :class="['custom-select--item', {active: item.isRefined}]"
                            >
                              {{ item.label }}
                              <span class="count">{{ item.count.toLocaleString() }}</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </ais-refinement-list>
                  </div>
                </div>
                <div class="filter-set border-b border-grey-200">
                  <div class="filter-heading heading mt-12 text-14 uppercase py-4 cursor-pointer">ABV</div>
                  <div class="filter-expander">
                    <ais-refinement-list :limit="100" attribute="abv" :sort-by="abvSort">
                      <div slot-scope="{ items, refine, createURL }" class="custom-select">
                        <ul class="custom-select--options">
                          <li v-for="item in items" :key="item.value">
                            <a
                                :href="createURL(item)"
                                @click.prevent="refine(item.value)"
                                :class="['custom-select--item', {active: item.isRefined}]"
                            >
                              {{ abvLabel(item.label) }}
                              <span class="count">{{ item.count.toLocaleString() }}</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </ais-refinement-list>
                  </div>
                </div>
                <div class="filter-set border-b border-grey-200">
                  <div class="filter-heading heading mt-12 text-14 uppercase py-4 cursor-pointer">Sustainability</div>
                  <div class="filter-expander">
                    <ais-refinement-list :limit="100" attribute="properties" :sort-by="['name:asc']">
                      <div slot-scope="{ items, refine, createURL }" class="custom-select">
                        <ul class="custom-select--options">
                          <li v-for="item in items" :key="item.value">
                            <a
                                :href="createURL(item)"
                                @click.prevent="refine(item.value)"
                                :class="['custom-select--item', {active: item.isRefined}]"
                            >
                              {{ item.label }}
                              <span class="count">{{ item.count.toLocaleString() }}</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </ais-refinement-list>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ais-panel>
      <ais-panel>
        <div slot="default" slot-scope="{ hasRefinements }"
             class="current-filters bg-grey-150 p-8 flex flex-col md:flex-row md:items-start" v-show="hasRefinements">
          <ais-current-refinements class="flex-grow mr-8">
            <div class="text-white w-full" slot-scope="{ items, createURL }" v-if="items.length">

              <ul class="md:flex md:flex-row md:flex-wrap">
                <li v-for="item in items" :key="item.attribute" class="p-4 bg-grey-100 block mt-2 md:mr-2">
                  <div class="leading-1 text-12">
                    <span v-if="item.label === 'pricePoint'">Price Point</span>
                    <span v-if="item.label === 'style'">Wine Style</span>
                    <span v-if="item.label === 'type'">Wine Type</span>
                    <span v-if="item.label === 'abv'">ABV</span>
                    <span v-if="item.label === 'variety'">Variety</span>
                    <span v-if="item.label === 'region'">Region</span>
                    <span v-if="item.label === 'state'">State</span>:
                  </div>
                  <ul class="flex flex-row flex-wrap -mx-1 mt-4">
                    <li class="m-1"
                        v-for="refinement in item.refinements"
                        :key="[
                refinement.attribute,
                refinement.type,
                refinement.value,
                refinement.operator
              ].join(':')"
                    >
                      <button
                          class="bg-white py-2 pl-2 pr-8 relative text-black text-12 hover:bg-grey-200 hover:text-white transition-colors duration-300 focus:outline-none text-left inline-block leading-1"
                          :href="createURL(refinement)"
                          @click.prevent="item.refine(refinement)"
                      >
                        {{ item.label === 'pricePoint' ? refinement.label.substring(2) : refinement.label }}
                        <svg aria-hidden="true" width="10" height="10" focusable="false" data-prefix="far"
                             data-icon="times"
                             class="svg-inline--fa fa-times fa-w-10 absolute right-3 top-1/2 transform -translate-y-1/2"
                             role="img" xmlns="http://www.w3.org/2000/svg"
                             viewBox="0 0 320 512">
                          <path fill="currentColor"
                                d="M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z"></path>
                        </svg>
                      </button>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </ais-current-refinements>
          <ais-clear-refinements>
            <div slot-scope="{ canRefine, refine, createURL }" class="text-white">
              <button
                  class="clear-filters-button flex-shrink-0 bg-grey-150px-8 p-4 text-grey-400 font-semibold text-14 uppercase hover:text-white transition-colors duration-300 focus:outline-none flex flex-row items-center justify-center leading-1 min-w-0 mt-8"
                  :href="createURL()"
                  @click.prevent="refine"
                  v-if="canRefine"
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 27.87 28.543"
                     class="stroke-current mr-4 flex-shrink-0">
                  <g id="Action_Go_Back" data-name="Action_Go Back" transform="translate(1.435 1.393)">
                    <g id="Group_5" data-name="Group 5">
                      <path id="Stroke_1" data-name="Stroke 1" d="M0,0,25,25.758" fill="none" stroke-miterlimit="10"
                            stroke-width="4"/>
                      <path id="Stroke_3" data-name="Stroke 3" d="M25,0,0,25.758" fill="none" stroke-miterlimit="10"
                            stroke-width="4"/>
                    </g>
                  </g>
                </svg>
                Clear Filters
              </button>
            </div>
          </ais-clear-refinements>
        </div>
      </ais-panel>

      <div class="flex flex-row items-center mt-8 justify-end">
        <div class="text-grey-400 text-16 mt-px">Sort by</div>
        <ais-sort-by
            :class-names="{
                  'ais-SortBy-select': 'sort-select'
                }"
            :items="[
                      { value: 'products_name_asc', label: 'Name A-Z' },
                      { value: 'products_name_desc', label: 'Name Z-A' },
                      { value: 'products_state_asc', label: 'State A-Z' },
                      { value: 'products_state_desc', label: 'State Z-A' },
                      { value: 'products_region_asc', label: 'Region A-Z' },
                      { value: 'products_region_desc', label: 'Region Z-A' },
                    ]"
        >
        </ais-sort-by>
      </div>

      <ais-state-results>
        <div slot-scope="{query, nbHits, index}">
          <algolia-stats :index="index" update-count="products"></algolia-stats>

          <div v-if="nbHits">
            <!--            <ais-stats class="mt-12 text-center">-->
            <!--              <p class="text-grey-400" slot-scope="{ hitsPerPage, nbPages, nbHits, page, processingTimeMS, query }">-->
            <!--                Showing <span class="text-grey-900">{{ nbHits }} products</span><span v-if="query"> for <span class="text-grey-900">{{ query }}</span></span>-->
            <!--              </p>-->
            <!--            </ais-stats>-->
            <ais-hits class="mt-16">
              <!--              <div slot-scope="{ items }" class="products-list flex flex-row flex-wrap -mx-4">-->
              <!--                <div class="product-tile w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 p-4" v-for="product in items" :key="'product' + product.objectID">-->
              <!--                  <router-link :to="`/expo/${product.winerySlug}/${product.slug}`" class="block product-inner bg-grey-100 pl-12 pb-12 text-white h-full">-->
              <!--                    <div class="image">-->
              <!--                      <div class="image-outer-container bg-white bg-opacity-10 pr-12 pt-12 pb-12">-->
              <!--                        <div class="image-inner-container max-w-20 md:max-w-32 m-auto">-->
              <!--                          <div class="relative">-->
              <!--                            <div class="image-container relative pb-bottle">-->
              <!--                              <img :src="`${product.image}?w=300`" :alt="product.name" class="block absolute left-0 top-0 w-full h-full object-cover max-w-none">-->
              <!--                            </div>-->
              <!--                          </div>-->
              <!--                        </div>-->
              <!--                      </div>-->
              <!--                    </div>-->
              <!--                    <div class="brand mt-12 font-bold uppercase text-primary-500 pr-8 leading-3">-->
              <!--                      <ais-highlight :hit="product" attribute="brand"/>-->
              <!--                    </div>-->
              <!--                    <div class="title mt-2 text-18 font-bold pr-8 leading-3">-->
              <!--                      <ais-highlight :hit="product" attribute="name"/>-->
              <!--                    </div>-->
              <!--                    <div class="region mt-4 opacity-50 pr-8 leading-3">-->
              <!--                      <ais-highlight :hit="product" attribute="region"/>, <span class="uppercase"><ais-highlight :hit="product" attribute="state"/></span></div>-->
              <!--                  </router-link>-->
              <!--                </div>-->
              <!--              </div>-->


              <div slot-scope="{ items }" class="product-list-results">
                <masonry
                    :cols="{default: 4, 1023: 3, 767:2, 400:1}"
                    :gutter="{default: '30px'}"
                >
                  <product-result-tile v-for="product in items" :key="'product' + product.objectID" :product="product"/>
                </masonry>
              </div>
            </ais-hits>
            <div class="pagination mt-12 flex flex-row justify-center">
              <ais-pagination @page-change="pageChange" :padding="1" class="md:hidden">
                <ul class="flex flex-row space-x-2"
                    slot-scope="{
                currentRefinement,
                nbPages,
                pages,
                isFirstPage,
                isLastPage,
                refine,
                createURL
              }"
                >
                  <li>
                    <a class="btn-pagination text-white bg-grey-100"
                       :href="createURL(currentRefinement - 1)"
                       @click.prevent="refine(currentRefinement - 1)"
                    >
                      ‹
                    </a>
                  </li>
                  <li v-for="page in pages" :key="page">
                    <a class="btn-pagination"
                       :href="createURL(page)"
                       :class="[{ 'text-white bg-grey-100' : page !== currentRefinement}, { 'text-black bg-white' : page === currentRefinement}]"
                       @click.prevent="refine(page)"
                    >
                      {{ page + 1 }}
                    </a>
                  </li>
                  <li>
                    <a class="btn-pagination text-white bg-grey-100"
                       :href="createURL(currentRefinement + 1)"
                       @click.prevent="refine(currentRefinement + 1)"
                    >
                      ›
                    </a>
                  </li>
                </ul>
              </ais-pagination>
              <ais-pagination @page-change="pageChange" class="hidden md:block">
                <ul class="flex flex-row space-x-2"
                    slot-scope="{
                currentRefinement,
                nbPages,
                pages,
                isFirstPage,
                isLastPage,
                refine,
                createURL
              }"
                >
                  <li>
                    <a class="btn-pagination text-white bg-grey-100"
                       :href="createURL(currentRefinement - 1)"
                       @click.prevent="refine(currentRefinement - 1)"
                    >
                      ‹
                    </a>
                  </li>
                  <li v-for="page in pages" :key="page">
                    <a class="btn-pagination"
                       :href="createURL(page)"
                       :class="[{ 'text-white bg-grey-100' : page !== currentRefinement}, { 'text-black bg-white' : page === currentRefinement}]"
                       @click.prevent="refine(page)"
                    >
                      {{ page + 1 }}
                    </a>
                  </li>
                  <li>
                    <a class="btn-pagination text-white bg-grey-100"
                       :href="createURL(currentRefinement + 1)"
                       @click.prevent="refine(currentRefinement + 1)"
                    >
                      ›
                    </a>
                  </li>
                </ul>
              </ais-pagination>
            </div>
          </div>
          <div v-if="!nbHits" class="mt-8 text-16">No results found for the query: <q>{{ query }}</q></div>
        </div>
      </ais-state-results>

      <!--      <ais-configure :query="query" :disjunctive-facets-refinements.camel="{ 'region': ['Canberra'] }"></ais-configure>-->
      <ais-configure :query="query"></ais-configure>

    </ais-instant-search>
  </div>
</template>

<script>
import algoliasearch from 'algoliasearch/lite';
import historyRouter from '@/customHistory';
import {simple as simpleMapping} from 'instantsearch.js/es/lib/stateMappings';
// import 'instantsearch.css/themes/algolia-min.css';
import AlgoliaStats from "@/components/AlgoliaStats";
import $ from 'jquery'
import Vue from 'vue'
import VueMasonry from 'vue-masonry-css'

Vue.use(VueMasonry);
// import VueSlider from 'vue-slider-component';
// import 'vue-slider-component/theme/default.css'
import ProductResultTile from "@/components/ProductResultTile";
import Analytics from '@/analytics'
import {debounce} from 'debounce'

export default {
  name: 'ProductSearch',
  components: {ProductResultTile, AlgoliaStats},
  props: {
    query: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      searchClient: algoliasearch(
          process.env.VUE_APP_ALGOLIA_APPID,
          process.env.VUE_APP_ALGOLIA_APIKEY
      ),
      hits: 0,
      sort: this.$store.state.productSort,
      showFilters: false,
      routing: {
        router: historyRouter(),
        stateMapping: simpleMapping(),
      },
      filterState: null,
      filterRegion: null,
      initialised: false
    }
  },
  methods: {
    productSearchFunction(helper) {
      helper.search()
      this.trackProductSearch(helper)
    },
    // productSearchFunction: debounce(function (helper) {
    //   if (this.initialised) {
    //     Analytics.trackPage({
    //       title: 'Expo',
    //       url: `${location.origin}/expo?query=${helper.state.query}&page=${helper.state.page}&abv=${helper.state.disjunctiveFacetsRefinements.abv.join(',')}&pricePoint=${helper.state.disjunctiveFacetsRefinements.pricePoint.join(',')}&properties=${helper.state.disjunctiveFacetsRefinements.properties.join(',')}&region=${helper.state.disjunctiveFacetsRefinements.region.join(',')}&state=${helper.state.disjunctiveFacetsRefinements.state.join(',')}&style=${helper.state.disjunctiveFacetsRefinements.style.join(',')}&type=${helper.state.disjunctiveFacetsRefinements.type.join(',')}&variety=${helper.state.disjunctiveFacetsRefinements.variety.join(',')}`,
    //       path: `/expo?query=${helper.state.query}&page=${helper.state.page}&abv=${helper.state.disjunctiveFacetsRefinements.abv.join(',')}&pricePoint=${helper.state.disjunctiveFacetsRefinements.pricePoint.join(',')}&properties=${helper.state.disjunctiveFacetsRefinements.properties.join(',')}&region=${helper.state.disjunctiveFacetsRefinements.region.join(',')}&state=${helper.state.disjunctiveFacetsRefinements.state.join(',')}&style=${helper.state.disjunctiveFacetsRefinements.style.join(',')}&type=${helper.state.disjunctiveFacetsRefinements.type.join(',')}&variety=${helper.state.disjunctiveFacetsRefinements.variety.join(',')}`,
    //       referrer: `${location.origin}/expo`
    //     })
    //   }
    //   this.initialised = true
    //   helper.search()
    // }, 200),
    toggleFilters() {
      this.showFilters = !this.showFilters
      if (this.showFilters) {
        $(this.$el).find('.filter-container').slideDown()
      } else {
        $(this.$el).find('.filter-container').slideUp()
      }
    },
    pageChange() {
      $('html, body').animate({scrollTop: $('.results').offset().top - $('header .main').height() - 20}, 250)
    },
    transformPricePoint(items) {
      return items.map(item => ({
        ...item,
        label: item.label.substring(2),
      }));
    },
    onSelect(refine, item) {
      refine(item.value);
    },
    onRemove(key, refine, item) {
      this[key].splice(this[key].indexOf(item), 1);
      refine(item.value);
    },
    abvSort (a, b) {
      console.log('comparing', a, b)
      if (a.name === '15% and above') {
        return -1
      }
      if (b.name === '15% and above') {
        return 1
      }
      if (a.name === 'Under 10%') {
        return 1
      }
      if (b.name === 'Under 10%') {
        return -1
      }
      return 0
    },
    abvLabel (label) {
      if (label === '10% - 15%') {
        return '10% - 14.9%'
      }
      return label
    }
  },
  mounted() {
    console.log('prod mounted')
    // const self = this
    // if (this.$route.fullPath.indexOf('?wineries') === -1 && this.$route.fullPath.indexOf('?products') === -1)  {
    //   const sorts = ['products_name_asc', 'products_name_desc', 'products_brand_asc', 'products_brand_desc', 'products_price_asc', 'products_price_desc']
    //   setTimeout(function () {
    //     self.sort = sorts[Math.floor(Math.random() * sorts.length)];
    //   }, 500)
    // }
    $('.product-search .filter-heading').click(function (e) {
      e.preventDefault();
      $(this).toggleClass('active');
      if ($(this).hasClass('active')) {
        $(this).next('.filter-expander').slideDown(100)
      } else {
        $(this).next('.filter-expander').slideUp(100)
      }
    });
  },
  created() {
    this.trackProductSearch = debounce(function (helper) {
      Analytics.trackPage({
        title: 'Expo',
        url: `${location.origin}/expo?query=${helper.state.query}&page=${helper.state.page}&abv=${helper.state.disjunctiveFacetsRefinements.abv.join(',')}&pricePoint=${helper.state.disjunctiveFacetsRefinements.pricePoint.join(',')}&properties=${helper.state.disjunctiveFacetsRefinements.properties.join(',')}&region=${helper.state.disjunctiveFacetsRefinements.region.join(',')}&state=${helper.state.disjunctiveFacetsRefinements.state.join(',')}&style=${helper.state.disjunctiveFacetsRefinements.style.join(',')}&type=${helper.state.disjunctiveFacetsRefinements.type.join(',')}&variety=${helper.state.disjunctiveFacetsRefinements.variety.join(',')}`,
        path: `/expo?query=${helper.state.query}&page=${helper.state.page}&abv=${helper.state.disjunctiveFacetsRefinements.abv.join(',')}&pricePoint=${helper.state.disjunctiveFacetsRefinements.pricePoint.join(',')}&properties=${helper.state.disjunctiveFacetsRefinements.properties.join(',')}&region=${helper.state.disjunctiveFacetsRefinements.region.join(',')}&state=${helper.state.disjunctiveFacetsRefinements.state.join(',')}&style=${helper.state.disjunctiveFacetsRefinements.style.join(',')}&type=${helper.state.disjunctiveFacetsRefinements.type.join(',')}&variety=${helper.state.disjunctiveFacetsRefinements.variety.join(',')}`,
        referrer: `${location.origin}/expo`
      })
    }, 500)
  }
}
</script>

<style lang="scss">

.filter-expander {
  display: none;
}

.filter-heading {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3E%3Cpath stroke='%23FFFFFF' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3E%3C/svg%3E");
  background-position: right 0.5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
}

.ais-RefinementList--noRefinement:after {
  content: 'No filters';
  display: block;
  margin-bottom: 10px;
  opacity: 0.2;
  font-size: 12px !important;
}

.product-list-results > div > div:nth-child(even) {
  margin-top: -30px;
}

.vue-slider-dot-tooltip-inner {
  background-color: #3b3b3b !important;
  border-color: #3b3b3b !important;
  font-size: 12px !important;
}

.vue-slider-rail {
  background-color: #1A1A1A !important;
}

.vue-slider-process {
  background-color: #fff !important;
}

.ais-RefinementList-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: -5px;
}

.ais-RefinementList-item {
  margin: 5px;
}

.clear-filters-button {
  width: 100%;
  @media(min-width: 768px) {
    width: 146px;
  }
}

.ais-RefinementList-label {
  padding: 10px;
  font-size: 12px !important;
  color: #FFF;
  background-color: #1A1A1A;
  display: block;
  line-height: 1 !important;
  cursor: pointer;

  &:hover {
    background-color: #222222;
  }

  input {
    position: absolute;
    opacity: 0;
    visibility: hidden;
  }

  .ais-RefinementList-labelText {
    font-size: 12px !important;
  }

  .ais-RefinementList-count {
    padding: 0 !important;
    background-color: transparent !important;
    color: #686868;
    font-size: 12px !important;

    &:before {
      display: inline-block;
      content: '(';
    }

    &:after {
      display: inline-block;
      content: ')';
    }
  }
}

.ais-RefinementList-item--selected {
  .ais-RefinementList-label {
    color: #FFF;
    background-color: #333;

    &:hover {
      background-color: #444;
    }

    .ais-RefinementList-count {
      color: #686868;
    }
  }
}

.heading {
  color: #FFF;
}

.custom-select {
  position: relative;
}

.custom-select--options {
  max-height: 120px;
  overflow: auto;

  @media(min-width: 768px) {
    max-height: 240px;
  }
}

.custom-select--options::-webkit-scrollbar {
  width: 12px;
}

.custom-select--options::-webkit-scrollbar-track {
  background-color: #222;
}

.custom-select--options::-webkit-scrollbar-thumb {
  background-color: #999;
  box-shadow: inset 0 0 0 1px #222;
}

.custom-select--item {
  padding: 10px;
  font-size: 12px !important;
  color: #FFF;
  background-color: #1A1A1A;
  display: block;
  line-height: 1 !important;
  cursor: pointer;
  border-bottom: 1px solid #111;

  .count {
    color: #686868;
    font-size: 12px !important;
    white-space: nowrap;

    &:before {
      display: inline-block;
      content: '(';
    }

    &:after {
      display: inline-block;
      content: ')';
    }
  }

  &:hover {
    background-color: #222222;
  }

  &.active {
    background-color: #333;

    &:hover {
      background-color: #444;
    }
  }
}
</style>

